import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Layout from '../../components/layout/layout';
import EventHero from '../../components/event-hero';
import About from '../../components/about';
import FeaturedSpeaker from '../../components/featuredspeaker';
import QuickLinks from '../../components/quicklinks';
import KeyReminders from '../../components/keyreminders';

function EventDetail({ data, location }) {
  const eventData = data.contentfulPageEvent;
  const eventHeroData = {
    title: eventData.title,
    recordedDate: eventData.recordedDate,
    eventDuration: eventData?.eventDuration,
    category: eventData.category,
    shortDescription: eventData.eventShortDescription.eventShortDescription,
    eventLocation: eventData?.eventLocation,
    eventDate: eventData.eventDate,
    eventDateRange: eventData?.eventDateRange,
    registerEvent: eventData?.eventRegistration,
    embeddedVideo: eventData?.eventVideo?.embeddedUrl.embeddedUrl,
    eventImage: eventData?.eventImage,
    updatedAt: eventData?.updatedAt,
    buttons: eventData?.buttons
  };
  const aboutData = {
    logo: eventData.logo.gatsbyImageData,
    title: eventData.eventTitle,
    description: eventData.eventDescription
  };

  const eventDetails = {
    category: eventData.category,
    addToCalender: eventData?.addToCalendar,
    registerLink: eventData?.eventRegistration
  };

  const Page = 'EVENT';

  return (
    <Layout
      legalFootNote={eventData?.legalFootNote?.legalFootNote}
      location={location}
      pageName={eventHeroData.title}
      eventDetails={eventDetails}
    >
      { data.contentfulPageEvent?.category === 'Past Event' && (
        <>
          <EventHero data={eventHeroData} />
          {data.contentfulPageEvent.recapSynopsis
          && <KeyReminders data={data.contentfulPageEvent?.recapSynopsis} category={eventData.category} /> }
          {data.contentfulPageEvent?.quickLinks && data.contentfulPageEvent?.quickLinks?.title && (
            <Container sx={{ padding: { lg: '60px 0px',
              xs: '58px 24px 58px 24px' } }}
            >
              <Typography variant='h3' sx={{ fontWeight: 'bold', MarginTop: '30px !important' }}>{data.contentfulPageEvent?.quickLinks?.title}</Typography>
              <Typography variant='headingdividerline' />
              <QuickLinks data={data.contentfulPageEvent?.quickLinks} />
            </Container>
          )}
        </>
      )}

      { data.contentfulPageEvent?.category !== 'Past Event' && (
        <>
          <EventHero data={eventHeroData} />
          <About data={aboutData} value={Page} tags={eventData?.metadata?.tags || []} />
          {eventData?.featuredSpeakers && (<FeaturedSpeaker featuredSpeaker={eventData.featuredSpeakers} />)}
          {/* As per new CR, the Related Information Section is changing to Quick Link Section */}
          {data.contentfulPageEvent?.quickLinks && data.contentfulPageEvent?.quickLinks?.title && (
            <Container sx={{ padding: { lg: '60px 0px', xs: '58px 24px 58px 24px' } }}>
              <Typography variant='h3' sx={{ fontWeight: 'bold' }}>{data.contentfulPageEvent?.quickLinks?.title}</Typography>
              <Typography variant='headingdividerline' />
              {data.contentfulPageEvent?.quickLinks?.quickLink && (<QuickLinks data={data.contentfulPageEvent.quickLinks} />)}
            </Container>
          )}
        </>
      )}

    </Layout>
  );
}

export const data = graphql`
  query EventDetail($slug: String) {
    contentfulPageEvent(slug: {eq: $slug}) {
      metadata {
        tags {
          id
          name
          contentful_id
        }
      }
      name
      title
      slug
      category
      eventShortDescription {
        eventShortDescription
      }
      eventDate(formatString: "MMMM DD, YYYY")
      eventDateRange
      eventLocation
      recordedDate(formatString: "MMMM DD, YYYY")
      updatedAt(formatString: "MMMM DD, YYYY")
      eventDuration
      recapSynopsis {
        ...recapsynopsis
      }
      eventVideo {
        embeddedUrl {
          embeddedUrl
        }
      }
      eventImage {
        file {
          url
          fileName
        }
        gatsbyImageData(
          cornerRadius: 10,
          aspectRatio: 1.78
          width: 728
          quality: 100
          resizingBehavior: SCALE
          jpegProgressive: true
          formats: WEBP
          placeholder: BLURRED
        )
      }
      logo {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          width: 313
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
      eventTitle
      eventDescription {
        raw
      }
      liveEvent {
        title
        ... on ContentfulCompExternalLink {
          url {
            url
          }
          openInANewTab
          logo {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
      featuredSpeakers {
        title
        shortDescription
        image {
          gatsbyImageData(
            aspectRatio: 1
            jpegProgressive: true
            formats: WEBP
            resizingBehavior: SCALE
            placeholder: BLURRED
            height: 175
          )
        }
      }
      legalFootNote {
        legalFootNote
      }
      quickLinks {
        ...eventQuickLink
      }
      eventRegistration {
        ...links
      }
      addToCalendar {
        title
        url {
          url
        }
        openInANewTab
      }
      buttons {
        title
        link {
          ... on ContentfulInternalLink {
            openInANewTab
            entryTitle
            refPage {
              __typename
              ... on ContentfulPageUtility {
                id
                entryTitle
                contentful_id
                type
                slug
              }
            }
          }
          ... on ContentfulCompExternalLink {
            id
            openInANewTab
            title
            url {
              url
            }
          }
        }
      }
    }
  }

  fragment recapsynopsis on ContentfulCompInfo {
    title
    type
    detailPoints {
      raw
    }
    assetTitle
    assetList {
      ...assetLists
      ...ranInternalExternalLink
    }
  }

  fragment assetLists on ContentfulCompAssetLink {
    id
    title
    downloadLink {
      file {
        url
        fileName
        contentType
      }
    }
    openInANewTab
  }

  fragment ranInternalExternalLink on ContentfulRanCompLink {
    title
    link {
      ... on ContentfulInternalLink {
        openInANewTab
        entryTitle
        refPage {
          __typename
          ... on ContentfulPageProgram {
            slug
          }
          ... on ContentfulPageUtility {
            slug
          }
          ... on ContentfulPageEvent {
            slug
          }
          ... on ContentfulPageKnowledge {
            slug
          }
        }
      }
      ... on ContentfulCompExternalLink {
        title
        url {
          url
        }
        openInANewTab
        logo {
          file {
            fileName
            url
            contentType
          }
        }
      }
    }
  }

  fragment eventQuickLink on ContentfulCompQuickLinkList {
    title
    quickLink {
      title
      logo {
        file {
          url
        }
      }
      description {
        description
      }
      actionButton {
        ...eventQuickLinksButtonLink
      }
    }
  }

  fragment eventQuickLinksButtonLink on ContentfulRanCompLink {
    link {
      ... on ContentfulInternalLink {
        openInANewTab
        entryTitle
        refPage {
          __typename
          ... on ContentfulPageProgram {
            slug
          }
          ... on ContentfulPageUtility {
            slug
          }
          ... on ContentfulPageEvent {
            slug
          }
          ... on ContentfulPageKnowledge {
            slug
          }
        }
      }
      ... on ContentfulCompExternalLink {
        title
        url {
          url
        }
        openInANewTab
        logo {
          file {
            fileName
            url
            contentType
          }
        }
      }
    }
  }

  fragment links on ContentfulRanCompLink {
    title
    link {
      ... on ContentfulInternalLink {
        openInANewTab
        entryTitle
        refPage {
          __typename
          ... on ContentfulPageProgram {
            slug
          }
          ... on ContentfulPageUtility {
            slug
          }
          ... on ContentfulPageEvent {
            slug
          }
          ... on ContentfulPageKnowledge {
            slug
          }
        }
      }
      ... on ContentfulCompExternalLink {
        title
        url {
          url
        }
        openInANewTab
        logo {
          file {
            fileName
            url
            contentType
          }
        }
      }
    }
  }

`;

EventDetail.propTypes = {
  location: PropTypes.shape.isRequired
};

export default EventDetail;
