import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import EmbeddedVideo from '../embedded-video';
import ImageDisplay from '../image-display';
import { typeToPageMap } from '../../utils/constants';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: 'white',
  boxShadow: 'none',
  backgroundColor: 'transparent'
}));

const EventHeroContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  '& .BoxBackground': {
    flexGrow: 1,
    background: '#020249',
    backgroundImage: `image-set(
      url("/images/hero-bg.png") 1x,
      url("/images/hero-bg@2x.png") 2x
    )`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingBottom: '26px'
    // background: 'transparent linear-gradient(180deg, #005284 0%, #40A59D 100%) 0% 0% no-repeat padding-box;'
  },
  '& .MainContainer': {
    padding: '50px 0'
  },
  '& .headerText': {
    padding: '30px 0 !important'
  },
  '& .ParagraphText': {
    marginBottom: '30px !important'
  },
  '& .ParagraphText1': {
    marginBottom: '10px !important',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px !important'
    }
  },
  '& .IconAlign': {
    verticalAlign: 'middle !important',
    marginRight: '20px !important',
    color: '#FFFFFF'
  },
  '& .buttonWrapper': {
    marginTop: '20px !important',
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px !important',
      paddingBottom: '25px'
    }
  },
  '& .buttons': {
    marginTop: '10px !important',
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px !important',
      paddingBottom: '10px'
    }
  },
  '& .buttonEvent': {
    textTransform: 'capitalize !important',
    backgroundColor: '#474CCF !important',
    borderRadius: '8px !important'
  },
  '& .heroImage': {
    maxHeight: '408px !important'
  },
  '& iframe': {
    [theme.breakpoints.down('lg')]: {
      width: '276px',
      height: '100%'
    }

  }
}));

export default function EventHero({ data }) {
  return (
    <EventHeroContainer>
      <Box className='BoxBackground'>
        <Container>
          <Grid container spacing={{ xs: 0, md: 2 }} p={{ xs: '0', md: '50px 0 70px' }} className='MainContainer' flexDirection={{ xs: 'column-reverse', sm: 'row' }}>
            <Grid item xs={12} sm={5}>
              <Item>
                <Typography variant='herobody1'>{data.category}</Typography>
                <Typography variant='heroheading' className='headerText'>
                  {data.title}
                </Typography>
                <Typography variant='herobody1' className='ParagraphText'>{data.shortDescription}</Typography>

                {data.category === 'Past Event' && (
                  <>
                    <Typography variant='herobody1' className='ParagraphText1'>
                      Occurred on -
                      {' '}
                      {' '}
                      {data?.eventDateRange || data?.recordedDate}
                    </Typography>
                    <Typography variant='herobody1' className='ParagraphText1'>
                      Duration -
                      {' '}
                      {' '}
                      {data?.eventDuration}
                    </Typography>
                    <Typography component='div' className='buttonWrapper'>
                      {/* <Typography variant='button'>
                        <FavoriteBorderOutlinedIcon className='IconAlign' />
                      </Typography> */}
                      {/* <Typography variant='button'>
                        <ShareIcon className='IconAlign' />
                      </Typography> */}
                    </Typography>
                  </>
                )}

                {data.category !== 'Past Event' && (
                  <>
                    <Typography variant='herobody1' className='ParagraphText1'>
                      <CalendarTodayIcon className='IconAlign' />
                      {data?.eventDateRange || data?.eventDate}
                    </Typography>
                    <Typography variant='herobody1' className='ParagraphText1'>
                      <LocationOnIcon className='IconAlign' />
                      {data?.eventLocation}
                    </Typography>
                    <Typography component='div' className='buttonWrapper'>
                      {data?.registerEvent?.link?.url?.url && data?.registerEvent?.link?.title && (
                        <Typography variant='div' sx={{ mr: '10px' }}>
                          <Link href={data?.registerEvent?.link?.url?.url} target={data?.registerEvent?.link?.openInANewTab ? '_blank' : ''}>
                            <Button variant='contained' color='primary' className='buttonPost'>{data?.registerEvent?.link?.title}</Button>
                          </Link>
                        </Typography>
                      )}
                      {/* <Typography variant='button'>
                        <FavoriteBorderOutlinedIcon className='IconAlign' />
                      </Typography> */}
                      {/* <Typography variant='button'>
                        <ShareIcon className='IconAlign' />
                      </Typography> */}
                    </Typography>
                  </>
                )}
                <Grid container spacing={2} alignItems='flex-start' justifyContent='flex-start' flexDirection={{ xs: 'column', sm: 'row' }}>
                  {data?.buttons && data?.buttons.length
                    && (
                      <>
                        {data.buttons.map(b => {
                          const pageTypeName = b?.link?.refPage?.__typename;
                          return (
                            <Typography>
                              {
                                b?.link?.url?.url && b?.link?.title && (
                                  <Button href={b.link.url.url} sx={{ margin: '15px 0px 15px 15px' }} target={b?.link?.openInANewTab ? '_blank' : ''} className='downloadBtn'>
                                    {b?.link?.title}
                                  </Button>
                                )
                              }
                              {
                                b?.link?.refPage && b?.link?.refPage?.slug && b?.link?.entryTitle && (
                                  <Button href={typeToPageMap[pageTypeName] + b.link.refPage.slug} sx={{ margin: '15px 0px 15px 15px' }} target={b?.link?.openInANewTab ? '_blank' : ''} className='downloadBtn'>
                                    {b?.link?.entryTitle}
                                  </Button>
                                )
                              }
                            </Typography>
                          );
                        })}
                      </>
                    )}
                </Grid>
                <Typography variant='caption'>
                  Last Updated:
                  {' '}
                  {data?.updatedAt}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={7}>
              {data.embeddedVideo && <Item><EmbeddedVideo embeddedVideo={data.embeddedVideo} /></Item>}
              {!data.embeddedVideo && data.eventImage && (
                <Item sx={{ borderRadius: '10px', border: '4px solid #fff', padding: 0, backgroundColor: '#fff' }}>
                  <ImageDisplay data={data.eventImage} />
                </Item>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </EventHeroContainer>
  );
}
