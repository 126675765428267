/* eslint-disable react/prop-types */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Paper, Grid, Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ImageDisplay from '../image-display';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent'
}));

const FeaturedSpeakerContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  backgroundColor: '#E7E7E4 !important',
  '& .boxContainer': {
    flexGrow: 1,
    
  },
  '& .h5': {
    color: '#383838 !important',
    fontWeight: 'bold !important'
  },
  '& .divider1': {
    borderColor: '#214975 !important',
    margin: '0 auto 8px'
  },
  '& .speakerImage': {
    objectFit: 'contain !important',
    paddingTop: '25px !important'
  },
  '& .cardContainer': {
    height: '100%',
    boxShadow: '0px 3px 6px #00000029 !important',
    borderRadius: '15px !important'
  },
  '& .speakerCardContent1': {
    fontSize: '16px !important',
    textAlign: 'center',
    color: '#383838 !important',
    fontWeight: 'bold !important'
  },
  '& .speakerCardContent2': {
    fontSize: '14px !important',
    textAlign: 'center',
    color: '#383838 !important',
    padding: '0 20px !important'
  }
}));

export default function FeaturedSpeaker({ featuredSpeaker }) {
  return (
    <FeaturedSpeakerContainer>
      <Container sx={{padding:'0px !important'}}>
      <Box className='boxContainer' p={{ xs: '20px', lg: '60px 0px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <Typography variant='h3' className='h5'>FEATURED SPEAKER</Typography>
              <Typography variant='headingdividerline' />
            </Item>
          </Grid>
          {featuredSpeaker?.map(speaker => (
            <Grid item xs={12} sm={3}>
              <Card className='cardContainer'>
                <Box sx={{ textAlign: 'center', pt: 2 }}>
                  <ImageDisplay data={speaker.image} />
                </Box>
                <CardContent>
                  <Typography variant='headingdividerline' className='divider1' />
                  <Typography gutterBottom variant='h5' component='div' className='speakerCardContent1'>
                    {speaker.title}
                  </Typography>
                  {speaker?.shortDescription && (
                    <Typography variant='body1' color='text.secondary' className='speakerCardContent2'>
                      {speaker.shortDescription}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      </Container>
    </FeaturedSpeakerContainer>
  );
}
